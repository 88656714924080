<template>
  <v-container fluid>
    <v-row class="d-flex flex-column" no-gutters>
      <v-col class="top-bar-buttons-container">
        <v-row no-gutters row>
          <v-col>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn @click="agregarNuevoServicio()" v-on="on">
                  <v-icon color="teal">mdi-briefcase-plus-outline</v-icon>
                </v-btn>
              </template>
              <span>Agregar nuevo servicio</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="exportExcel('Clientes', 'Registro')"
                  elevation="2"
                  v-on="on"
                >
                  <v-icon color="teal">mdi-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Exportar a Excel</span>
            </v-tooltip>
          </v-col>
          <v-col class="search-right">
            <v-text-field
              append-icon="mdi-magnify"
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <DataTable :search="this.search" />
        <EditServiceDialog />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import { excelExport } from '@/plugins/excelExport.js';

import DataTable from './DataTable';
import EditServiceDialog from './EditServiceDialog';

export default {
  data: () => ({
    editar: false,
    buscar_dni: false,
    dialog: false,
    table: true,
    data: {},
    options: {},
    search: '',
    personal: [],
  }),
  computed: {
    ...mapState(['editServiceDialog', 'serviceToEdit']),
  },
  methods: {
    exportExcel(table, services) {
      excelExport(table, services);
    },
    async agregarNuevoServicio() {
      this.$store.state.serviceToEdit = {};
      this.$store.state.editServiceDialog = true;
    },
  },
  components: {
    DataTable,
    EditServiceDialog,
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/main.scss';
.search-right {
  text-align: end !important;
  max-width: 450px;
}
.top-bar-buttons-container {
  text-align: left !important;
}
</style>
