import { render, staticRenderFns } from "./AsideConfig.vue?vue&type=template&id=6cf80812&scoped=true&"
import script from "./AsideConfig.vue?vue&type=script&lang=js&"
export * from "./AsideConfig.vue?vue&type=script&lang=js&"
import style0 from "./AsideConfig.vue?vue&type=style&index=0&id=6cf80812&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cf80812",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCard,VImg,VTab,VTabItem,VTabs})
