<template>
  <!-- <v-container> -->
  <v-card min-height="400px">
    <v-data-table
      id="Clientes"
      :headers="headers"
      height="87vh"
      fixed-header
      :items="services"
      @click:row="editarServicio"
      class="elevation-1"
      :search="this.search"
      :loading="tableLoading"
      loading-text="Cargando servicios... Espere..."
      hide-default-footer
      disable-pagination
      no-data-text="No hay servicios aún..."
    >
    </v-data-table>
  </v-card>
  <!-- </v-container> -->
</template>
<script>
import { mapState } from 'vuex';

import { getService } from '@/api/services/getServices.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'DataTable',
  data() {
    return {
      tableLoading: true,
      services: [],
      headers: [
        {
          text: 'Documento',
          value: 'doi',
        },
        {
          text: 'Nombre/Razón social',
          value: 'nombre',
        },
        {
          text: 'Nombre referencial',
          value: 'nombre_referencial',
        },

        {
          text: 'Dirección',
          value: 'direccion',
        },
        {
          text: 'Teléfono',
          value: 'telefono',
        },
        {
          text: 'Email',
          value: 'email',
        },
      ],

      data: {},
    };
  },
  props: {
    search: String,
  },
  created() {
    this.getServices();
  },
  computed: {
    ...mapState(['editServiceDialog']),
  },
  watch: {
    editServiceDialog() {
      if (!this.editServiceDialog) {
        //se puede actualizar solamente el componente de UI para no hacer otra llamada al backend
        this.getServices();
      }
    },
    search() {
      this.searcher();
    },
  },
  methods: {
    editarServicio(row) {
      this.$store.state.serviceToEdit = row;
      this.$store.state.editServiceDialog = true;
    },

    async getServices() {
      const { error, services, tokenExpired } = await getService();
      if (error) {
        // console.error('error en el created', error);
      }
      if (services) {
        this.data = services;
        this.services = this.data;
        this.tableLoading = false;
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
    },
    searcher() {
      const word = this.search.toLowerCase();
      this.services = this.data;
      let newArray = [];
      for (let i in this.services) {
        if (
          this.services[i].nombre.toLowerCase().includes(word) ||
          this.services[i].nombre_referencial.toLowerCase().includes(word) ||
          this.services[i].direccion.toLowerCase().includes(word) ||
          this.services[i].doi.toLowerCase().includes(word)
        ) {
          newArray.push(this.services[i]);
        }
      }
      this.services = newArray;
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';

.column-header {
  font-size: 15px !important;
  font-weight: bold !important;
  color: teal !important;
}
.v-data-table::v-deep th {
  font-size: 16px !important;
  font-weight: bold !important;
  color: teal !important;
}
.table-container {
  max-height: 88vh;
  background-color: blue !important;
  // overflow-y: scroll;
}
#table {
  max-height: 88vh;
  // overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  margin: auto;
  td,
  th {
    border: 1px solid rgba(97, 92, 92, 0.253);
  }
  tr:nth-child(even) {
    cursor: pointer;
    background-color: $secondary;
  }
  th {
    height: 50px;
    text-align: justify;
  }
  td {
    height: 30px;
    padding: 0px 10px;
    text-align: justify;
  }
}
#table::-webkit-scrollbar {
  height: 10px;
  width: 4px;
  background: $main;
}
#table::-webkit-scrollbar-thumb {
  background: #313d35;
  border-radius: 10px;
}
.v-text-field {
  max-width: 400px;
}
#map,
#mapa2 {
  max-height: 100%;
  max-width: 50vw;
}
.mdi_pointer {
  cursor: pointer;
  position: relative;
  bottom: 7px;
  left: 16px;
}
.p-relative {
  position: relative;
  top: 0px;
  background: black;
}
#floating-panel {
  position: absolute;
  top: 10px;
  left: 25%;
  z-index: 5;
  background-color: #fff;
  padding: 5px;
  border: 1px solid #999;
  text-align: center;
  line-height: 30px;
  padding-left: 10px;
}

.p-30 {
  padding: 30px;
}
.centrado {
  justify-content: center;
  .v-btn {
    margin: 10px auto;
  }
}
// .m-20 {
//   width: 100%;
//   height: 92vh;
//   background: $tables-bg;
//   margin: 40px auto 0px 0px;
//   padding: 10px 20px;
//   border-radius: 0px 5px 5px 5px;
//   border: 3px solid $main;
//   .scroll {
//     height: 74vh;
//     overflow: scroll;
//     overflow-y: scroll;
//   }
//   #table {
//     max-height: 74vh;
//     overflow-y: scroll;
//     width: 100%;
//     margin: auto;
//     tr:nth-child(even) {
//       background-color: $secondary;
//     }
//     th {
//       height: 30px;
//       text-align: justify;
//     }
//     td {
//       height: 30px;
//       padding: 0px 10px;
//       text-align: justify;
//     }
//   }
//   .scroll::-webkit-scrollbar {
//     width: 4px;
//     background: #efeff6;
//   }
//   .scroll::-webkit-scrollbar-thumb {
//     background: #313d35;
//     border-radius: 10px;
//   }
//   .btn-group {
//     display: flex;
//     justify-content: space-between;
//     margin: 5px auto 10px auto;
//     .v-btn {
//       margin: 0 2px;
//       .v-image {
//         margin-right: 5px;
//       }
//     }
//     .buscador {
//       height: 50px;
//       .v-btn {
//         position: relative;
//         right: -74px;
//         top: -61px;
//         box-shadow: none !important;
//         background-color: transparent;
//       }
//     }
//   }
// }
// @media (max-width: 735px) {
//   .m-20 {
//     width: 70vw;
//     margin: 40px 0px 5px;
//   }
// }
// @media (max-width: 900px) {
//   .m-20 {
//     width: 20vw;
//   }
// }
</style>
