import fetch from 'isomorphic-fetch';

let ENDPOINT_URL = process.env.VUE_APP_SERVICES_EDIT;

async function updateService(service) {
  const bodyParams = {
    _id: service._id,
    doi: service.doi.toString(),
    nombre: service.nombre,
    nombre_referencial: service.nombre_referencial,
    email: service.email,
    direccion: service.direccion,
    telefono: service.telefono,
    latlng: {
      lat: service.latlng.lat,
      lng: service.latlng.lng,
    },
    token: localStorage.getItem('token'),
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { updateService };
