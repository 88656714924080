<template>
  <v-dialog
    v-model="editServiceDialog"
    max-width="60vw"
    max-height="50vh"
    persistent
    class="dialog-container"
  >
    <v-card>
      <v-card-title
        class="text-h5 justify-center"
        style="color: teal; font-weight: bold"
      >
        {{
          !service.nombre
            ? 'Agregar nuevo servicio'
            : `Editando ${service.nombre}`
        }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation :key="mapContainer">
          <v-row>
            <v-col>
              <v-text-field
                :rules="nameRules"
                v-model="service.doi"
                label="DNI o RUC"
                required
              ></v-text-field>

              <v-text-field
                v-model="service.nombre"
                :rules="nameRules"
                label="Razón social"
                required
              ></v-text-field>

              <v-text-field
                v-model="service.nombre_referencial"
                :rules="nameRules"
                label="Nombre Referencial"
                required
              ></v-text-field>

              <v-text-field
                v-model="service.email"
                label="Email"
                :rules="emailRules"
                required
              ></v-text-field>

              <v-flex>
                <v-text-field
                  label="Dirección"
                  placeholder="Dirección, distrito, provincia"
                  v-model="service.direccion"
                  @keypress.enter.prevent
                  :rules="direccionRules"
                >
                  <template slot="append-outer">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" @click="loadMapByAddress"
                          >mdi-map-marker</v-icon
                        >
                      </template>
                      <span>Ver dirección en mapa</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-flex>

              <v-text-field
                v-model="service.telefono"
                label="Teléfono"
              ></v-text-field>
            </v-col>

            <v-col class="mapa-servicio">
              <div ref="map"></div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">Cancelar</v-btn>
        <v-btn color="success" text @click="validate">Guardar</v-btn>
        <v-btn
          color="red darken-1"
          text
          @click="showDeleteAlert"
          :disabled="!service._id ? true : false"
          >Eliminar</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import gmapsInit from '@/plugins/gmaps.js';

import { newService } from '@/api/services/newServices.js';
import { updateService } from '@/api/services/updateServices.js';
import { deleteService } from '@/api/services/deleteServices.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'EditServiceDialog',
  data() {
    return {
      mapContainer: 0,
      google: {},
      geocoder: {},
      service: {},
      direccionRules: [
        (v) => !!v || 'Datos requeridos',
        (v) => v.length <= 250 || 'Los caracteres ingresados son menores',
      ],
      nameRules: [
        (v) => !!v || 'Datos requeridos',
        (v) => v.length <= 100 || 'Los caracteres ingresados son menores',
      ],
      emailRules: [
        (v) => !!v || 'E-mail es requerido',
        (v) => /.+@.+/.test(v) || 'Debe ingresar un email válido',
      ],
      direccionValida: false,
      valid: false,
    };
  },
  computed: {
    ...mapState(['serviceToEdit', 'editServiceDialog']),
  },
  methods: {
    showDeleteAlert() {
      this.$swal
        .fire({
          title: `Atención!`,
          text: `Va a eliminar el servicio/cliente ${this.service.nombre} `,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: 'teal',
          cancelButtonText: 'Cancelar',
          confirmButtonColor: 'red',
          confirmButtonText: 'Eliminar',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.deleteService();
          }
        });
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showErrorAlert() {
      this.$swal.fire({
        title: `Error`,
        text: 'La dirección debe ser ubicada en el mapa',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    saveData() {
      !this.service._id ? this.newServices(this.service) : this.updateService();
    },
    async deleteService() {
      const { error, message, tokenExpired } = await deleteService(
        this.service._id
      );
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (message) {
        this.showSuccessAlert();
        this.$store.state.serviceToEdit = {};
        this.$store.state.editServiceDialog = false;
      }
    },
    async updateService() {
      const { error, message, tokenExpired } = await updateService(
        this.service
      );
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (message) {
        this.showSuccessAlert();
        this.$store.state.serviceToEdit = {};
        this.$store.state.editServiceDialog = false;
      }
    },
    async newServices() {
      const { error, message, tokenExpired } = await newService(this.service);
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (message) {
        this.showSuccessAlert();
        this.$store.state.serviceToEdit = {};
        this.$store.state.editServiceDialog = false;
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (!this.direccionValida) {
          this.showErrorAlert();
          return;
        }
        this.saveData();
      }
    },
    closeDialog() {
      this.$store.state.editServiceDialog = false;
    },
    async getMap() {
      const google = await gmapsInit();
      const geocoder = new google.maps.Geocoder();
      this.google = google;
      this.geocoder = geocoder;
    },
    async loadNewMap() {
      try {
        if (map) this.google.maps.setMap(null);

        let latLng = {
          lat: -12.04318,
          lng: -77.02824,
        };
        if (this.service._id) {
          latLng = this.service.latlng;
        }
        const map = await new this.google.maps.Map(this.$refs.map, {
          disableDefaultUI: true,
          center: latLng,
          zoom: 16,
        });

        this.geocodeAddress(this.geocoder, map, this.service, latLng);
      } catch (error) {
        console.log(error);
      }
    },

    async geocodeAddress(geocoder, resultsMap, service, latLng) {
      let parent = this;
      try {
        await geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === 'OK') {
            resultsMap.setCenter(results[0].geometry.location);

            let newLatLng = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            };

            service.latlng = newLatLng;

            service.direccion = results[0].formatted_address;
            parent.direccionValida = true;
            const marker = new parent.google.maps.Marker({
              map: resultsMap,
              draggable: true,
              position: results[0].geometry.location,
            });
            const handleEvent = (event) => {
              const latlng = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
              };
              geocoder.geocode({ location: latlng }, (results, status) => {
                if (status === 'OK') {
                  if (results[0]) {
                    (service.latlng.lat = latlng.lat),
                      (service.latlng.lng = latlng.lng),
                      (service.direccion = results[0].formatted_address);
                    parent.direccionValida = true;
                  }
                }
              });
            };
            marker.addListener('dragend', handleEvent);
          }
        });
      } catch (error) {
        console.log(error);
      }
    },

    async loadMapByAddress() {
      try {
        let parent = this;
        if (map) this.google.maps.setMap(null);
        const map = await new this.google.maps.Map(this.$refs.map, {
          disableDefaultUI: true,
          zoom: 16,
        });
        await this.geocoder.geocode(
          { address: this.service.direccion },
          (results, status) => {
            if (status === 'OK') {
              if (results[0]) {
                let newLatLng = {
                  lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng(),
                };
                (parent.service.latlng = newLatLng),
                  (parent.service.direccion = results[0].formatted_address);
                parent.direccionValida = true;
                map.setCenter(results[0].geometry.location);
              }
            }
          }
        );
        const marker = await new this.google.maps.Marker({
          map,
          center: this.service.latlng,
          position: this.service.latlng,
          draggable: true,
        });
        const handleEvent = (event) => {
          const latlng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };
          parent.geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === 'OK') {
              if (results[0]) {
                (parent.service.latlng.lat = latlng.lat),
                  (parent.service.latlng.lng = latlng.lng),
                  (parent.service.direccion = results[0].formatted_address);
                parent.direccionValida = true;
              }
            }
          });
        };
        marker.addListener('dragend', handleEvent);
      } catch (error) {
        console.log(error);
      }
    },
  },

  created() {
    this.getMap();
  },
  watch: {
    serviceToEdit() {
      this.direccionValida = false;
      if (this.serviceToEdit._id) {
        this.direccionValida = true;
        this.service = this.$store.state.serviceToEdit;
      } else {
        this.service = {
          doi: '',
          direccion: '',
          nombre: '',
          nombre_referencial: '',
          telefono: '',
          latlng: {
            lat: 0,
            lng: 0,
          },
        };
      }
      this.$nextTick(function() {
        this.loadNewMap();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';
.mapa-servicio {
  display: grid !important;
  max-height: 100%;
  max-width: 50vw;
}
.dialog-container {
  overflow: scroll;
}
</style>
