<template>
  <v-card class="container ma-0 pa-0">
    <v-tabs vertical>
      <v-tab>
        Servicios
      </v-tab>
      <div class="btn-group">
        <router-link to="/dashboard">
          <v-btn>
            <v-img
              max-width="20px"
              cover
              :src="require('@/assets/menu-icons/home.svg')"
            />
            Inicio
          </v-btn>
        </router-link>
        <router-link to="/">
          <v-btn>
            <v-img
              max-width="20px"
              cover
              :src="require('@/assets/menu-icons/endsession.svg')"
            />
            Salir
          </v-btn>
        </router-link>
      </div>
      <v-tab-item class="m-20">
        <ServicesContainer />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
<script>
import ServicesContainer from './ServicesContainer';
export default {
  name: 'AsideConfig',
  components: {
    ServicesContainer,
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';
.btn-group {
  justify-content: space-around;
  flex-direction: column;
  display: flex;
  position: absolute;
  bottom: 50px;
  .v-btn {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    justify-content: flex-start;
  }
  .v-image {
    margin-right: 10px;
  }
}
.v-tab--active {
  background-color: $main;
  color: #fff !important;
}
.container {
  background-color: $general-bg;
}
.flex {
  display: flex;
  flex: 0.03 0 auto;
}
a {
  text-decoration: none !important;
  color: $main !important;
}
.column {
  flex-direction: column;
}
.m-20 {
  right: 24px;
  background-color: $secondary;
  padding: 10px 20px;
  border-radius: 0px 5px 5px 5px;
  border: 3px solid $main;
}
@media (max-width: 735px) {
  .m-20 {
    margin: 40px 0px 5px;
  }
}
</style>
